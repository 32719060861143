import React from 'react'
import { graphql } from 'gatsby'

import PageFactory from '@/components/PageFactory'

class NotFoundPage extends React.Component<any, any> {
  render() {
    const { page, header, footer } = this.props.data
    return <PageFactory page={page} header={header} footer={footer} />
  }
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    page: datoCmsNotFoundPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      sections {
        __typename
        ...allSections
      }
    }

    header: datoCmsNavSection {
      ...NavSection
    }

    footer: datoCmsFooter {
      ...Footer
    }
  }
`
